<template>
    <div>
        <Loading :active="loadingAgrupador" :is-full-page="true" />
        <v-alert
            class="mt-2 mb-1"
            dense
            dismissible
            outlined
            v-show="mensagem"
            :type="tipoMensagem"
            border="left"
            >{{ mensagem }}</v-alert
        >
        <ConfirmacaoModal
            v-if="mostrarConfirmacao"
            :showDialog="mostrarConfirmacao"
            :tituloModal="tituloModal"
            :textoModal="textoModal"
            :btnConfirmarEmit="'confirmarUpload'"
            @confirmarUpload="confirmarUpload"
            @fecharModal="mostrarConfirmacao = false"
        />
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left" class="my-2">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        cloud_upload </v-icon
                    >Títulos > Upload de Títulos
                </v-card-text>
            </v-card>
            <v-card>
                <v-container justify="center">
                    <v-row align="center">
                        <v-col sm="4">
                            <v-file-input
                                class="mb-n7"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                                label="Selecione o arquivo (.xls/.xlsx/ .xlsm)"
                                :rules="rules"
                                show-size
                                :loading="loadingUpload"
                                :disabled="desabilitaBoxEnvio"
                                chips
                                outlined
                                ref="myFileInput"
                                id="fileInput"
                                data-cy="uploadInput"
                                @change="uploadFileReference"
                                v-model="file"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="12" md="2" class="mx-auto">
                            <v-btn
                                :color="colorBtn"
                                large
                                width="100%"
                                class="white--text"
                                :loading="loadingUpload"
                                :disabled="uploadOk"
                                @click="upload"
                                data-cy="enviarUpload"
                            >
                                Enviar
                                <v-icon class="ml-2">cloud_upload</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col sm="12" md="6">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        class="ma-3"
                                        color="grey lighten-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        help
                                    </v-icon>
                                </template>
                                <span
                                    >Utilize esse template para fazer upload de
                                    títulos para o sistema da Recuperi</span
                                >
                            </v-tooltip>
                            <v-btn
                                class="ma-2 white--text"
                                :color="colorBtn"
                                fullwidth
                                @click="templateUpload"
                            >
                                <v-icon class="ma-2">cloud_download</v-icon>
                                Baixar Template
                            </v-btn>
                            <v-btn
                                class="ma-2 white--text"
                                :color="colorBtn"
                                fullwidth
                                @click="templateDicas"
                            >
                                <v-icon class="ma-2">info</v-icon>
                                Manual de Upload
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>

                <div v-show="mensagemAlerta">
                    <v-alert type="error">
                        Apresentante inativo. Não é possível realizar o Upload
                        de Títulos.
                    </v-alert>
                </div>
            </v-card>
        </div>
        <v-alert
            class="mx-3 mb-0 pa-3 font-weight-bold text-center"
            border="top"
            color="primary"
            type="info"
            v-model="snackbarApresentante"
        >
            Importante: você está logado em:
            {{ `${nomeApresentante} (${cdApresentante})` }}, através do usuário:
            {{ computedGetUserName }}. <br />
            Todas as alterações serão aplicadas ao apresentante
            {{ `${nomeApresentante} (${cdApresentante})` }}.
        </v-alert>
        <v-alert
            v-if="listaAgrupadoresValidos.length > 0"
            class="mx-3 mb-0 mt-3 pa-3 font-weight-bold text-center"
            border="top"
            color="orange"
            type="warning"
        >
            Atenção: Ao preencher o Novo Campo Agrupador, certifique-se de que o
            código está preenchido corretamente
        </v-alert>
        <Snackbar
            v-if="mostrarSnackbar"
            :mostrarSnackbar="mostrarSnackbar"
            :timeoutSnackbar="6000"
            :corSnackbar="corSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="mostrarSnackbar = false"
        />
        <v-dialog
            class="ma-3 pa-3 font-weight-bold"
            border="top"
            color="error"
            type="info"
            v-model="snackbarNumeroExcedido"
        >
            Erro: Número de títulos excede a quantidade permitida para upload
            via XLSX. Importante: O limite de títulos para upload através de um
            arquivo XLSX é de até 3 mil títulos (3000). Caso a quantidade de
            títulos for maior que 3 mil títulos, recomendamos dividir em 2 ou
            mais arquivos para o processamento. Para grandes quantidades,
            recomendamos a utilização de upload via API.
        </v-dialog>
        <div align="center" justify="center" class="pa-3">
            <v-card>
                <v-container>
                    <v-data-table
                        :headers="headers"
                        :items="titulos"
                        :options.sync="options"
                        :item-class="itemRowBackground"
                        :server-items-length="totalTitulos"
                        :loading="loading"
                        :footer-props="{
                            itemsPerPageOptions: [20, 100, 500]
                        }"
                        item-key="id_arquivo"
                        show-expand
                        :expanded.sync="expanded"
                        loading-text="Pesquisando Histórico de Remessas..."
                        no-data-text="Nenhum registro encontrado"
                        no-results-text="Nenhuma Remessa Encontrada..."
                    >
                        <template
                            v-slot:[`item.contador_de_titulos_revisao`]="{
                                item
                            }"
                        >
                            <span
                                v-if="item.contador_de_titulos_revisao != 0"
                                title="Este arquivo contém títulos em revisão que precisam ser corrigidos, favor acessar 'consulta de títulos'"
                                :style="{ color: 'red' }"
                                >{{ item.contador_de_titulos_revisao }}</span
                            >
                            <span v-else :style="{ color: 'black' }">{{
                                item.contador_de_titulos_revisao
                            }}</span>
                        </template>

                        <template v-slot:[`item.st_arquivo`]="{ item }">
                            <v-btn
                                v-if="item.st_arquivo === 'E'"
                                class="error ma-2"
                                depressed
                                small
                            >
                                <v-icon small class="ma-2">
                                    mdi-alert-circle
                                </v-icon>
                                {{ formatStatusArquivo(item) }}
                            </v-btn>

                            <v-btn
                                v-if="item.st_arquivo === 'P'"
                                class="grey lighten-4 ma-2"
                                depressed
                                small
                            >
                                <!-- <v-icon small class="ma-2">
                                    mdi-timer-sand
                                </v-icon> -->
                                <!-- {{ formatStatusArquivo(item) }} -->
                                NÃO CONFIRMADO
                            </v-btn>

                            <v-btn
                                v-if="item.st_arquivo === 'F'"
                                class="success ma-2"
                                depressed
                                small
                            >
                                <v-icon small class="ma-2">
                                    mdi-check
                                </v-icon>
                                {{ formatStatusArquivo(item) }}
                            </v-btn>

                            <v-btn
                                v-if="item.st_arquivo === 'I'"
                                class="grey lighten-4 ma-2"
                                depressed
                                small
                            >
                                <v-icon small class="ma-2">
                                    mdi-timer-sand
                                </v-icon>
                                {{ formatStatusArquivo(item) }}
                            </v-btn>

                            <v-btn
                                v-if="item.st_arquivo === 'C'"
                                class="success ma-2 px-2 py-4"
                                depressed
                                small
                            >
                                <v-icon small class="my-2 mr-2">
                                    mdi-check-circle
                                </v-icon>
                                CONFIRMADO EM: <br />
                                {{ formatStatusConfirmado(item) }}
                            </v-btn>

                            <v-btn
                                v-if="item.st_arquivo === 'D'"
                                class="ma-2"
                                depressed
                                small
                            >
                                <v-icon small class="ma-2">
                                    cancel
                                </v-icon>
                                {{ formatStatusArquivo(item) }}
                            </v-btn>
                        </template>
                        <template v-slot:[`item.dt_criacao`]="{ item }">
                            <span>{{ formatDataCriacao(item) }}</span>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <span
                                    class="
                                        grey--text
                                        subtitle
                                        font-weight-black
                                    "
                                    >Nome do Arquivo:
                                    {{ item.nm_arquivo_cliente }}</span
                                >
                            </td>
                        </template>
                        <template slot="item.action" slot-scope="props">
                            <UploadTituloDetalhes
                                :index="props.index"
                                :detalhe_remessa="props.item"
                                @reload="aplicarFiltros"
                            ></UploadTituloDetalhes>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card>
        </div>
        <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o Upload -->
            <v-card class="">
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-col cols="12" sm="12" align="left">
                        <v-toolbar-title>Instruções de Upload</v-toolbar-title>
                    </v-col>
                </v-toolbar>
                <v-card-text>
                    <DicasUpload @fecharModal="closeDialog" />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
        <!-- Informativo Popup -->
        <v-dialog
            v-model="dialogInicio"
            max-width="70%"
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card class="">
                <div class="pa-8">
                    <v-card-text
                        v-bind:style="{ color: primaryColorFont }"
                        class="font-weight-black display-1"
                    >
                        Antes de iniciar, faça o download do Template
                    </v-card-text>
                    <v-row>
                        <v-col sm="6">
                            <v-card-text
                                >Para que possa realizar o upload corretamente,
                                utilize nosso template que está disponível para
                                download na tela de upload no canto
                                superior.</v-card-text
                            >
                            <v-card-text
                                >Seu upload pode não funcionar caso não utilize
                                nosso template, pois todos os campos são
                                mapeados.
                            </v-card-text>
                            <v-btn
                                class="ma-2"
                                color="green darken-1 white--text"
                                data-cy="confirmarTemplate"
                                @click="desativaDialog"
                            >
                                <v-icon dark> done </v-icon>
                                OK, vou utilizar o Template
                            </v-btn>
                        </v-col>
                        <v-col sm="6">
                            <v-img
                                src="@/assets/manual/print-botao_pb.png"
                            ></v-img>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
        <v-dialog
            v-if="dialogDicas"
            v-model="dialogDicas"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o Upload -->
            <v-card class="">
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Manual de Upload</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <ManualUpload @fecharModal="closeDialog" />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import historicoRemessaService from '@/services/historicoRemessaService';
import TituloService from '@/services/TituloService';
import UploadTituloDetalhes from '@/components/UploadTituloDetalhes';
import Snackbar from '@/components/Snackbar';
import Loading from 'vue-loading-overlay';
import { getMensagemRequest, listarAgrupadores } from '@/helpers/Utilitarios';
import * as Formatter from '@/helpers/Formatter';
import DicasUpload from '@/components/DicasUpload';
import ManualUpload from '@/components/ManualUpload';
import moment from 'moment';

export default {
    name: 'UploadTitulos',
    components: {
        UploadTituloDetalhes,
        DicasUpload,
        ManualUpload,
        ConfirmacaoModal,
        Snackbar,
        Loading
    },
    data() {
        return {
            mostrarSnackbar: false,
            loadingAgrupador: false,
            corSnackbar: '',
            mensagemSnackbar: '',
            tituloModal: '',
            textoModal: '',
            registros_pagina: 10,
            mostrarConfirmacao: false,
            showPagination: false,
            isMounted: false,
            numero_paginas: 1,
            totalTitulos: 0,
            titulos: [],
            options: {
                page: 1,
                itemsPerPage: 20
            },
            snackbarNumeroExcedido: false,
            snackbarApresentante: true,
            rules: [
                value =>
                    !value ||
                    (value.size < this.maxFileSize && this.checkfile(value)) ||
                    'O tamanho permitido é de até 6MB | Formatos permitidos: .xls/.xlsx!'
            ],
            expanded: [],
            file: null,
            maxFileSize: 6000000,
            mensagem: null,
            dialogDicas: false,
            value: null,
            uploadOk: false,
            mensagemAlerta: false,
            desabilitaBoxEnvio: false,
            tipoMensagem: null,
            dialog: false,
            dialogInicio: true,
            historico: [],
            loading: false,
            colorBtn: '#6c80e2',
            loadingUpload: false,
            historicoTituloPromisse: null,
            primaryColorFont: '#959ea6',
            headers: [
                {
                    text: 'Código Remessa',
                    value: 'id_arquivo',
                    width: '2%',
                    sortable: true
                },
                {
                    text: 'Data de Criação',
                    value: 'dt_criacao',
                    sortable: false
                },
                {
                    text: 'Status do Arquivo',
                    value: 'st_arquivo',
                    align: 'center'
                },
                { text: 'Titulos processados', value: 'titulos_processados' },
                { text: 'Titulos irregulares', value: 'titulos_irregulares' },
                {
                    text: 'Titulos revisão',
                    value: 'contador_de_titulos_revisao'
                },
                { text: 'Nome Usuário', value: 'user_name' },
                { text: 'Id Usuário', value: 'id_user' },
                { text: 'Origem', value: 'tp_origem_descricao' },
                { text: '', value: 'action', sortable: false }
            ]
        };
    },
    watch: {
        options: {
            handler() {
                if (this.isMounted) {
                    this.aplicarFiltros();
                }
            },
            deep: true
        }
    },
    computed: {
        computedGetUserName() {
            return this.$store.getters.getUserName;
        },
        cdApresentante() {
            return this.$store.getters.cdApresentante;
        },
        nomeApresentante() {
            return this.$store.getters.nomeApresentante;
        },
        listaAgrupadoresValidos() {
            const agrupadores = this.$store.getters.listaAgrupadores;
            return agrupadores.filter(
                agrupador => agrupador.ds_agrupador != null
            );
        }
    },
    methods: {
        getMensagemRequest,
        listarAgrupadores,
        aplicarFiltros(isMounted = false) {
            this.buscarTitulos().then(data => {
                if (isMounted) this.isMounted = isMounted;
                this.titulos = data.arquivos;
                this.totalTitulos = data.total;
                if (data.total === 0) {
                    this.numero_paginas = 1;
                } else {
                    this.numero_paginas =
                        data.total % this.registros_pagina == 0
                            ? data.total / this.registros_pagina
                            : parseInt(data.total / this.registros_pagina) + 1;
                }
                this.showPagination = this.numero_paginas > 1;
                this.sorterFilter();
            });
        },
        sorterFilter() {
            return new Promise(resolve => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                let items = this.titulos;
                const total = items.length;

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]];
                        const sortB = b[sortBy[0]];

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1;
                            if (sortA > sortB) return -1;
                            return 0;
                        } else {
                            if (sortA < sortB) return -1;
                            if (sortA > sortB) return 1;
                            return 0;
                        }
                    });
                }

                if (itemsPerPage > 0) {
                    items = items.slice(
                        (page - 1) * itemsPerPage,
                        page * itemsPerPage
                    );
                }

                setTimeout(() => {
                    resolve({
                        items,
                        total
                    });
                }, 1000);
            });
        },
        buscarTitulos() {
            this.loading = true;
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            return new Promise((resolve, reject) => {
                try {
                    let service = new historicoRemessaService(
                        Vue.http,
                        this.$store
                    );
                    service.listaHistoricoRemessa(page, itemsPerPage).then(
                        response => {
                            if (response.status == 200) {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    arquivos,
                                    numero_linhas: total
                                } = response.body;

                                this.loading = false;
                                resolve({
                                    arquivos,
                                    total
                                });
                            }
                        },
                        error => {
                            if (
                                error.body.msg ==
                                'Session expired! Authentication is required!'
                            ) {
                                this.loading = false;
                                this.mensagem = true;
                                this.mensagemRetorno =
                                    'Sua sessão expirou devido ao tempo de inatividade, faça login novamente para ter acesso ao sistema (' +
                                    error.body.msg +
                                    ') ';
                                this.alertType = 'Error';
                                this.alertColor = 'error';
                                console.log(error);
                            }
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        getFileName() {
            var fileInput = document.getElementById('fileInput');
            var fileInfo = {
                file_name: fileInput.files.item(0).name,
                file_size: fileInput.files.item(0).size,
                file_type: fileInput.files.item(0).type
            };

            return fileInfo;
        },
        remessaDetalhe() {
            this.dialog = true;
        },
        desativaDialog() {
            this.dialogInicio = false;
        },
        templateUpload() {
            this.dialog = true;
            this.dialogLoading = false;
        },
        templateDicas() {
            this.dialogDicas = true;
            this.dialogLoading = false;
        },
        uploadFileReference(e) {
            this.file = e;
            if (!this.file || this.file.size > this.maxFileSize) {
                this.uploadOk = true;
            } else {
                this.uploadOk = false;
            }
        },
        formatDataCriacao(item) {
            return (
                Formatter.formatData(item.dt_criacao) + ' ' + item.hr_criacao
            );
        },
        itemRowBackground: function(item) {
            if (item.st_arquivo === 'E') {
                return 'style-1';
            }
            if (item.st_arquivo === 'F') {
                return 'style-2';
            }
        },
        closeDialog() {
            this.dialog = false;
            this.dialogDicas = false;
            this.dialogInicio = false;
        },
        formatStatusConfirmado(upload) {
            return `${moment(upload.dt_alteracao).format('DD/MM/YYYY HH:mm')}`;
        },
        formatStatusArquivo(item) {
            return Formatter.formatArquivoStatus(item.st_arquivo);
        },
        upload() {
            if (!this.file) {
                this.tipoMensagem = `error`;
                this.mensagem = 'Selecione um arquivo!';
                return;
            } else if (!this.checkfile(this.file)) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo não é do tipo .xls/.xlsx/.xlsm';
            } else if (this.file.size > this.maxFileSize) {
                this.tipoMensagem = `error`;
                this.mensagem = 'O arquivo possui mais de 6MB';
            } else {
                if (this.remessaPendenteConfirmacao()) {
                    this.tituloModal = 'Remessa de Confirmação Pendente';
                    this.textoModal =
                        'Ao realizar um novo upload, remesssas anteriores pendentes de confirmação serão descartadas. <br /><br /> Deseja prosseguir com o Upload?';
                    this.mostrarConfirmacao = true;
                } else {
                    this.confirmarUpload();
                }
            }
        },
        remessaPendenteConfirmacao() {
            const ultimaRemessa = this.titulos[0];
            if (
                ultimaRemessa?.st_arquivo == 'P' &&
                ultimaRemessa?.confirmacao == 1
            ) {
                return true;
            }
            return false;
        },
        confirmarUpload() {
            this.mostrarConfirmacao = false;

            this.loadingUpload = true;

            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .uploadTilulos(this.file, this.getFileName())
                .then(
                    response => {
                        response.status;
                        response.statusText;
                        response.headers.get('Expires');
                        this.formatDataCriacao(this.historico);
                        this.tipoMensagem = `info`;
                        this.mensagem =
                            'Upload executado. Verifique os resultados abaixo';
                        this.uploadOk = true;
                        this.$refs.myFileInput.reset();
                        this.aplicarFiltros();
                    },
                    error => {
                        this.tipoMensagem = `error`;
                        this.mensagem = error;
                    }
                )
                .finally(() => {
                    this.loadingUpload = false;
                });
        },
        checkfile(sender) {
            var validExts = new Array(
                '.xlsm',
                '.xlsx',
                '.xls',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel'
            );
            var fileExt = sender.name;
            fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.includes(fileExt)) {
                return true;
            } else return false;
        },
        verificaUsuarioInativo() {
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.verificaUsuarioInativo().then(
                response => {
                    if (response.body.mensagem == 'inativo') {
                        this.uploadOk = true;
                        this.desabilitaBoxEnvio = true;
                        this.mensagemAlerta = true;
                    }
                    if (response.body.mensagem == 'ativo') {
                        this.uploadOk = true;
                    }
                },
                error => {
                    console.log(error.body);
                    this.uploadOk = true;
                    this.desabilitaBoxEnvio = true;
                    this.exibirSnackbar(
                        'red',
                        'Erro ao buscar status do Apresentante, tente novamente mais tarde.'
                    );
                }
            );
        },
        exibirSnackbar(corSnackbar, mensagemSnackbar) {
            this.mostrarSnackbar = true;
            this.corSnackbar = corSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        closeDialogInicio() {
            this.dialogInicio = false;
        },
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                this.exibirSnackbar(
                    'error',
                    'Erro ao buscar Agrupadores disponíveis.'
                );
            } finally {
                this.loadingAgrupador = false;
            }
        }
    },
    async mounted() {
        await this.checarAgrupadores();
        this.aplicarFiltros(true);
        this.verificaUsuarioInativo();
        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#959ea6';
            this.colorBtn = '#6c80e2';
        } else {
            this.primaryColorFont = '#959ea6';
            this.colorBtn = this.$vuetify.theme.themes.light.primary;
        }
    }
};
</script>
