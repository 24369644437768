export default class historicoRemessaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    listaHistoricoRemessa(page, itemsPerPage) {
        return this._http.post('remessapre/historico', {
            cd_apresentante: this._store.getters.cdApresentante,
            page: page,
            per_page: itemsPerPage
        });
    }

    confirmaRemessa(codigo_remessa) {
        return this._http.post('remessapre/aprovacao', {
            cd_apresentante: this._store.getters.cdApresentante,
            id_arquivo: codigo_remessa
        });
    }

    cancelaArquivo(codigo_remessa) {
        return this._http.post('remessapre/cancelamento_arquivo', {
            cd_apresentante: this._store.getters.cdApresentante,
            id_arquivo: codigo_remessa
        });
    }

    buscaResultados(codigo_remessa) {
        return this._http.post(
            'remessapre/retorno-xls',
            {
                cd_apresentante: this._store.getters.cdApresentante,
                id_arquivo: codigo_remessa
            },
            {
                responseType: 'arraybuffer'
            }
        );
    }
}
